import { MouseEventHandler, useState } from 'react';

import { DayPassAvailability, Product } from '@tgg/common-types';
import { getTodaysDate } from '@tgg/util';

export const useDayPassSelection = () => {
    const [isOpen, setIsOpen] = useState<{
        isNoDatesOverlayOpen: boolean;
        isDatesOverlayOpen: boolean;
    }>({ isNoDatesOverlayOpen: false, isDatesOverlayOpen: false });

    const [selectedProduct, setSelectedProduct] = useState<Product>(
        {} as Product,
    );

    const [selectedDayPassStartDate, setSelectedDayPassStartDate] = useState<
        DayPassAvailability | undefined
    >({} as DayPassAvailability);

    const [checkedIndex, setCheckedIndex] = useState<number | null>(0);

    const [isShowMoreDaysToggled, setIsShowMoreDaysToggled] =
        useState<boolean>(false);

    const todaysDate = getTodaysDate();

    const handleDayPassProductSelection = (product: Product) => {
        let indexOfFirstAvailableDay = -1;
        let selectedDayPassDay;
        let overlaysState = {
            isNoDatesOverlayOpen: false,
            isDatesOverlayOpen: false,
        };
        if (product.dayPassDays) {
            indexOfFirstAvailableDay = product.dayPassDays.findIndex(
                daypassDay => !daypassDay.isDisabled,
            );
            if (indexOfFirstAvailableDay > -1) {
                selectedDayPassDay =
                    product.dayPassDays[indexOfFirstAvailableDay];

                // removes all daypass days that occur before the first available day.
                const dayPassDaysFiltered = product.dayPassDays.filter(
                    (_, index) => {
                        return index >= indexOfFirstAvailableDay;
                    },
                );

                setSelectedProduct({
                    ...product,
                    dayPassDays: dayPassDaysFiltered,
                });

                overlaysState = {
                    ...overlaysState,
                    isDatesOverlayOpen: true,
                };
            } else {
                overlaysState = {
                    ...overlaysState,
                    isNoDatesOverlayOpen: true,
                };
            }
        } else {
            overlaysState = {
                ...overlaysState,
                isNoDatesOverlayOpen: true,
            };
        }

        setCheckedIndex(0);
        setIsOpen(overlaysState);
        setSelectedDayPassStartDate(selectedDayPassDay);
    };

    return {
        isOpen,
        setIsOpen,
        selectedProduct,
        selectedDayPassStartDate,
        setSelectedDayPassStartDate,
        checkedIndex,
        setCheckedIndex,
        isShowMoreDaysToggled,
        setIsShowMoreDaysToggled,
        todaysDate,
        handleDayPassProductSelection,
    };
};
