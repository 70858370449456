/* istanbul ignore file */
import { Button as MaterialButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { palette } from '../../theme';
import { StyledButtonProperties } from './PinoButton.types';

const StyledMaterialButton = styled(MaterialButton)<StyledButtonProperties>`
    padding: 0.55rem 1.4rem;
    font-size: 1.9rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3.2rem;
`;

export const StyledPinoButton = styled(StyledMaterialButton, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledButtonProperties>`
    padding: 0.2rem 1.5rem;
    color: ${palette.common.blue};
    font-weight: 500;
    font-size: 1.2rem;
    font-family: thegymgroupsans-Bold, sans-serif;
    text-transform: lowercase;
    background-color: ${palette.common.white};
    border: 1px solid;
    border-radius: 3.2rem;

    ${({ disabled }: StyledButtonProperties) => {
        if (disabled) {
            return `
                opacity: 0.5;
                cursor: not-allowed;
            `;
        }

        return ``;
    }}

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.4rem;
    }

    ${({ selected, $buttonLocation: variant }: StyledButtonProperties) => {
        if (selected && variant === 'card') {
            return `
                background-color: ${palette.common.blue};
                border-color: ${palette.common.blue};
            `;
        }
        return selected ? `border-color: ${palette.common.white}` : ``;
    }};

    &:focus {
        outline: 0.25rem solid ${palette.common.white};
        outline-offset: 0.25rem;
    }

    &:hover {
        ${({ selected, $buttonLocation: variant }: StyledButtonProperties) => {
            if (selected && variant === 'card') {
                return `
                background-color: ${palette.common.blue};
                border-color: ${palette.common.blue};
            `;
            }

            if (selected) {
                return `
                    background-color: ${palette.common.white};
                `;
            }

            return `
                background-color: ${palette.common.blue};
                color: ${palette.common.white};
                border: 1px solid transparent;
            `;
        }};
    }
`;
