import { Typography as MaterialTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { StyledPriceProperties } from './Price.types';

export const StyledPrice = styled(MaterialTypography, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<StyledPriceProperties>`
    color: ${({ $color }) => $color};
    font-weight: normal;
`;
