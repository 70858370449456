import { useMediaQuery } from '@mui/material';

import { theme } from '../../../theme';
import { StyledIcon } from './ProductOptionIcons.styled';
import { ProductOptionIconsProperties } from './ProductOptionIcons.types';

export const ProductOptionIcons = ({
    productTitle,
    color,
}: ProductOptionIconsProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconsSize = isDesktop ? 28 : 24;

    if (productTitle === 'ultimate') {
        return (
            <div>
                <StyledIcon
                    name="bringAFriend"
                    color={color}
                    size={iconsSize}
                />
                <StyledIcon name="dumbells" color={color} size={iconsSize} />
                <StyledIcon
                    name="twentyFourHours"
                    color={color}
                    size={iconsSize}
                />
                <StyledIcon name="homeGym" color={color} size={iconsSize} />
                <StyledIcon name="mobile" color={color} size={iconsSize} />
            </div>
        );
    }

    if (productTitle === 'standard') {
        return (
            <div>
                <StyledIcon name="dumbells" color={color} size={iconsSize} />
                <StyledIcon
                    name="twentyFourHours"
                    color={color}
                    size={iconsSize}
                />
            </div>
        );
    }

    if (productTitle === 'off-peak') {
        return (
            <div>
                <StyledIcon name="dumbells" color={color} size={iconsSize} />
            </div>
        );
    }

    if (productTitle === 'membershipStudent') {
        return (
            <div>
                <StyledIcon name="gymLocation" color={color} size={iconsSize} />
                <StyledIcon name="dumbells" color={color} size={iconsSize} />
                <StyledIcon
                    name="twentyFourHours"
                    color={color}
                    size={iconsSize}
                />
            </div>
        );
    }

    return <div data-testid="no-pino-title" />;
};
