import { membershipTypeToMembershipRange } from './googleAnalytics.helpers';
import {
    EventOne,
    EventTwo,
    MappedDataEvent,
    UIDataEvent,
} from './googleAnalytics.types';

export const dataEventMapper = (dataEvent: UIDataEvent): MappedDataEvent => {
    if (dataEvent.eventName === 'joinNow') {
        const joinNowDataEvent = dataEvent.eventData;

        const chooseTermMapper: EventTwo = {
            event: dataEvent.eventName,
            branchName: dataEvent.eventGymName,
            ctaPosition: 'Term Tile',
            productCategory: 'Membership',
            productRange: membershipTypeToMembershipRange(
                dataEvent.eventData.membershipType,
            ),
            productName: joinNowDataEvent.description,
            productType: dataEvent.eventData.membershipType,
            termType: joinNowDataEvent.price.frequency.termType ?? 'Months',
            termLength: joinNowDataEvent.price.frequency.term ?? 0,
            joiningFee: joinNowDataEvent.joiningFee ?? 0,
            initialPaymentAmount: joinNowDataEvent.price.value ?? 0,
            kickerPriceDuration:
                joinNowDataEvent.price.kickerPriceDuration ?? 0,
            kickerPriceMonthlyFee:
                joinNowDataEvent.price.kickerPriceMonthlyFee ?? 0,
        };

        return {
            key: dataEvent.eventName,
            data: chooseTermMapper,
        };
    }

    if (dataEvent.eventName === 'termSelect') {
        const termSelectDataEvent = dataEvent.eventData;

        const chooseTermMapper: EventTwo = {
            event: dataEvent.eventName,
            branchName: dataEvent.eventGymName,
            ctaPosition: 'Term Tile',
            productCategory: 'Membership',
            productRange: membershipTypeToMembershipRange(
                dataEvent.eventData.membershipType,
            ),
            productName: termSelectDataEvent.description,
            productType: dataEvent.eventData.membershipType,
            termType: termSelectDataEvent.price.frequency.termType ?? 'Months',
            termLength: termSelectDataEvent.price.frequency.term ?? 0,
            joiningFee: termSelectDataEvent.joiningFee ?? 0,
            initialPaymentAmount: termSelectDataEvent.price.value ?? 0,
            kickerPriceDuration:
                termSelectDataEvent.price.kickerPriceDuration ?? 0,
            kickerPriceMonthlyFee:
                termSelectDataEvent.price.kickerPriceMonthlyFee ?? 0,
        };

        return {
            key: dataEvent.eventName,
            data: chooseTermMapper,
        };
    }

    if (dataEvent.eventName === 'chooseTerm') {
        const chooseTermMapper: EventOne = {
            event: dataEvent.eventName,
            branchName: dataEvent.eventGymName,
            ctaPosition: 'Product Table',
            fromPrice: dataEvent.eventData.price.value,
            productCategory: 'Membership',
            productRange: membershipTypeToMembershipRange(
                dataEvent.eventData.membershipType,
            ),
        };

        return {
            key: dataEvent.eventName,
            data: chooseTermMapper,
        };
    }

    const productTableMapper: EventOne = {
        event: dataEvent.eventName,
        branchName: dataEvent.eventGymName,
        ctaPosition: 'Product Table',
        fromPrice: dataEvent.eventData.price.value,
        productCategory: 'Membership',
        productRange: membershipTypeToMembershipRange(
            dataEvent.eventData.membershipType,
        ),
    };

    return {
        key: dataEvent.eventName,
        data: productTableMapper,
    };
};
