import { StyledWasPrice } from './WasPrice.styled';
import { WasPriceProperties } from './WasPrice.types';

export const WasPrice = ({ wasPrice, isSubtle }: WasPriceProperties) => {
    return (
        <StyledWasPrice $isSubtle={isSubtle} data-testid="was-price">
            £{wasPrice}
        </StyledWasPrice>
    );
};

export default WasPrice;
