import Link from 'next/link';
import { useMemo, useState } from 'react';

import { palette } from '../../../theme';
import { BenefitsTable } from '../../BenefitsTable';
import { ButtonBase } from '../../Button';
import { Expander } from '../../Expander';
import { Icon } from '../../Icon';
import { MembershipPanel } from '../../MembershipPanel';
import { ExpandedBenefits } from '../ExpandedBenefits';
import { constants } from '../Membership.constants';
import {
    StyledExpanderSection,
    StyledRadioContainer,
    StyledJoinButtonContainer,
    StyledFixedMembershipParagraph,
    StyledHorizontalRule,
    StyledFixedHeading,
    StyledExpanderHeading,
    StyledPanelsWithHeaderWrapper,
    StyledPanelsFixedHeader,
} from '../Membership.styled';
import { a11yProperties, analyticsPayload } from '../utils';
import {
    MonthlyPanelsProperties,
    PanelArrayItem,
    ProductType,
} from './MonthlyPanels.types';
import { TggMembershipType } from '@tgg/common-types';
import { EventKey } from '@tgg/services';
import { capitalise } from '@tgg/util';

export const MonthlyPanels = ({
    gymName,
    liveIt,
    doIt,
    liveItFixed,
    productsAvailableAddons,
    ctaHref,
    onSelect,
    ctaText = 'Join now',
    panelsNotSelectable = false,
    showJoiningFee = true,
    initialSelection,
    showBestDealChip = true,
    showYourPlanChip = false,
}: MonthlyPanelsProperties) => {
    const [selectedProduct, setSelectedProduct] = useState<ProductType>(
        initialSelection || (liveIt && 'liveIt') || (doIt && 'doIt'),
    );

    const selectedProductData =
        selectedProduct === 'doIt'
            ? doIt
            : selectedProduct === 'liveIt'
            ? liveIt
            : liveItFixed;

    const onProductSelect = panelsNotSelectable
        ? () => {}
        : (name: ProductType) => setSelectedProduct(name);

    const panels: PanelArrayItem[] = useMemo(() => {
        const panelsArray = [];

        if (liveIt) {
            panelsArray.push({
                product: liveIt,
                name: 'liveIt',
                variant: 'green',
                align: doIt ? 'left' : 'center',
                bestDealChip: showBestDealChip && !liveItFixed,
                iconName: 'membershipLiveIt',
                benefits: {
                    title: constants[TggMembershipType.Live_It].title,
                    content: constants[TggMembershipType.Live_It].benefits,
                },
            } as const);
        }
        if (doIt) {
            panelsArray.push({
                product: doIt,
                name: 'doIt',
                variant: 'blue',
                align: liveIt ? 'left' : 'center',
                bestDealChip:
                    !showBestDealChip || liveItFixed ? false : 'placeholder',
                iconName: 'membershipDoIt',
                benefits: {
                    title: constants[TggMembershipType.Do_It].title,
                    content: constants[TggMembershipType.Do_It].benefits,
                },
            } as const);
        }
        return panelsArray;
    }, [liveIt, doIt, liveItFixed, showBestDealChip]);

    return (
        <>
            <StyledPanelsWithHeaderWrapper {...a11yProperties(0)}>
                <StyledPanelsFixedHeader variant="h3">
                    monthly memberships
                </StyledPanelsFixedHeader>
                <div>
                    {panels.map(
                        ({
                            name,
                            product,
                            variant,
                            align,
                            benefits,
                            bestDealChip,
                            iconName,
                        }) => (
                            <MembershipPanel
                                key={name}
                                membershipTitle={product.description}
                                iconName={iconName}
                                price={product.price}
                                {...(showJoiningFee
                                    ? {
                                          joiningFee: product.joiningFee || 0,
                                      }
                                    : {})}
                                variant={variant}
                                align={align}
                                ctaAlign="center"
                                bestDealChip={bestDealChip}
                                yourPlanChip={
                                    showYourPlanChip
                                        ? product.isCurrentPlan
                                            ? true
                                            : 'placeholder'
                                        : false
                                }
                                onClick={() => onProductSelect(name)}
                                selected={selectedProduct === name}
                                ctas={[
                                    <StyledRadioContainer
                                        key={name}
                                        $checked={selectedProduct === name}
                                        htmlFor={name}
                                        $variant={variant}
                                        className="radio-container"
                                    >
                                        <input
                                            key={name}
                                            type="radio"
                                            name="product"
                                            id={name}
                                            data-testid={`${name}-radio`}
                                            checked={selectedProduct === name}
                                            onChange={() =>
                                                onProductSelect(name)
                                            }
                                        />
                                        <div>
                                            <Icon
                                                name="tick"
                                                color={palette.grey[900]}
                                                size={28}
                                            />
                                        </div>
                                    </StyledRadioContainer>,
                                ]}
                            >
                                <BenefitsTable
                                    title={benefits.title}
                                    benefits={benefits.content.map(
                                        benefit => benefit.name,
                                    )}
                                    variant={variant}
                                    align={align}
                                />
                            </MembershipPanel>
                        ),
                    )}
                </div>
            </StyledPanelsWithHeaderWrapper>
            {liveItFixed && (
                <>
                    <StyledHorizontalRule />
                    <StyledFixedHeading variant="h3">
                        fixed membership
                    </StyledFixedHeading>
                    <MembershipPanel
                        iconName="membershipLiveIt"
                        membershipTitle={liveItFixed.description}
                        variant="alternative-green"
                        bestDealChip
                        showBorder
                        price={liveItFixed.price}
                        compact
                        joiningFee={liveItFixed.joiningFee || 0}
                        align="center"
                        ctaAlign="center"
                        onClick={() => {
                            setSelectedProduct('liveItFixed');
                        }}
                        selected={selectedProduct === 'liveItFixed'}
                        ctas={[
                            <StyledRadioContainer
                                key="liveItFixed"
                                $checked={selectedProduct === 'liveItFixed'}
                                htmlFor="liveItFixed"
                                $variant="green"
                                className="radio-container"
                            >
                                <input
                                    key="liveItFixed"
                                    type="radio"
                                    name="product"
                                    id="liveItFixed"
                                    data-testid="liveItFixed-radio"
                                    checked={selectedProduct === 'liveItFixed'}
                                    onChange={() => {
                                        setSelectedProduct('liveItFixed');
                                    }}
                                />
                                <div>
                                    <Icon
                                        name="tick"
                                        color={palette.grey[900]}
                                        size={28}
                                    />
                                </div>
                            </StyledRadioContainer>,
                        ]}
                    >
                        <StyledFixedMembershipParagraph gutterBottom={false}>
                            Get all the benefits and save even more, by paying
                            for a fixed term membership in advance.
                        </StyledFixedMembershipParagraph>
                    </MembershipPanel>
                </>
            )}
            {selectedProductData && (
                <StyledJoinButtonContainer>
                    {ctaHref ? (
                        <Link
                            href={
                                productsAvailableAddons?.[
                                    selectedProductData.id
                                ]
                                    ? '/join-now/extras/'
                                    : ctaHref
                            }
                            key={selectedProductData.id}
                            passHref
                        >
                            <ButtonBase
                                fullWidth
                                text={ctaText}
                                onClick={() => {
                                    onSelect(selectedProductData);
                                }}
                                data-testid="joinNowButton"
                                data-analytics-event={
                                    EventKey.GYM_PAGE_JOIN_NOW
                                }
                                data-analytics-payload={analyticsPayload(
                                    selectedProductData,
                                    selectedProduct === 'doIt'
                                        ? TggMembershipType.Do_It
                                        : TggMembershipType.Live_It,
                                    gymName,
                                )}
                            />
                        </Link>
                    ) : (
                        <ButtonBase
                            fullWidth
                            text={ctaText}
                            onClick={() => {
                                onSelect(selectedProductData);
                            }}
                            data-testid="selectMembershipOption"
                        />
                    )}
                </StyledJoinButtonContainer>
            )}
            <StyledExpanderSection>
                <StyledExpanderHeading variant="h2">
                    benefit descriptions
                </StyledExpanderHeading>
                {liveIt && (
                    <Expander title="Live It membership">
                        <ExpandedBenefits
                            benefits={
                                constants[TggMembershipType.Live_It].benefits
                            }
                        />
                    </Expander>
                )}
                {doIt && (
                    <Expander title="Do It membership">
                        <ExpandedBenefits
                            benefits={
                                constants[TggMembershipType.Do_It].benefits
                            }
                        />
                    </Expander>
                )}
                {liveItFixed && (
                    <Expander
                        title={`${capitalise(
                            liveItFixed.description,
                        )} membership`}
                    >
                        <ExpandedBenefits
                            benefits={
                                constants[TggMembershipType.Live_It_Fixed]
                                    .benefits
                            }
                        />
                    </Expander>
                )}
            </StyledExpanderSection>
        </>
    );
};
