import { Paragraph } from '../../Paragraph';
import { UnorderedList } from '../../UnorderedList';
import { FullBenefitsWrapper } from '../Membership.styled';
import { ExpandedBenefitsProperties } from './ExpandedBenefits.types';

export const ExpandedBenefits = ({ benefits }: ExpandedBenefitsProperties) => {
    return (
        <FullBenefitsWrapper>
            <UnorderedList>
                {benefits
                    .filter(benefit => benefit.name !== '-')
                    .map(benefit => (
                        <li key={benefit.name.replace(' ', '-').toLowerCase()}>
                            <Paragraph gutterBottom={false}>
                                <strong>
                                    {benefit.subtitle ?? benefit.name}
                                </strong>{' '}
                                - {benefit.description}
                            </Paragraph>
                        </li>
                    ))}
            </UnorderedList>
        </FullBenefitsWrapper>
    );
};
