import { useMediaQuery } from '@mui/material';

import { palette, theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { Icon } from '../Icon';
import { ProductHighlightPill } from '../ProductHighlightPill';
import { getDataTestIdString } from './PinoProductOption.helpers';
import {
    StyledBoldText,
    StyledCard,
    StyledCardHeader,
    StyledCardFooter,
    StyledHorizontalRule,
    StyledJoiningFeeText,
    StyledPinoOptionButton,
    StyledPrice,
    StyledPriceContainer,
    StyledStrikethroughPrice,
} from './PinoProductOption.styled';
import { PinoProductOptionProperties } from './PinoProductOption.types';
import { getKickerPricePostfixText } from './utils';
import { ProductVariant } from '@tgg/common-types';
import { addOrdinalSuffix, decimalToPoundsAndPence } from '@tgg/util';

export const PinoProductOption = ({
    product,
    pinoProductSelected,
    onSelectProductOption,
    title,
    orientation = 'horizontal',
    numberOfElements,
    pill,
}: PinoProductOptionProperties) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const { alternate, contentColor } = getPinoColor(title);

    const squeezeBestValue = numberOfElements > 3;
    const wrapPriceDescription = numberOfElements > 2 && !isDesktop;

    const {
        isKickerPriceInForce,
        kickerPriceDuration,
        kickerPriceMonthlyFee,
        value,
        frequency,
    } = product.price;

    const isKickerInUse =
        isKickerPriceInForce && kickerPriceDuration && kickerPriceMonthlyFee;

    const isFixedOrStudentProductVariant =
        product.variant === ProductVariant.Fixed ||
        product.variant === ProductVariant.Student;

    const isDayPassProductVariant = product.variant === ProductVariant.DayPass;

    const showPricePostfix =
        isKickerInUse ||
        isFixedOrStudentProductVariant ||
        isDayPassProductVariant;

    const dividerColor = pinoProductSelected
        ? palette.common.blue
        : palette.grey[100];

    const pinoButtonContent = pinoProductSelected ? (
        <Icon name="tick" size={18} />
    ) : (
        'select'
    );

    const finalPrice =
        product.variant === ProductVariant.Monthly ||
        product.variant === ProductVariant.DayPass
            ? decimalToPoundsAndPence(value, true)
            : decimalToPoundsAndPence(Math.trunc(value), false);

    return (
        <StyledCard
            $pinoPrimary={contentColor}
            $pinoAlternate={alternate}
            $orientation={orientation}
            $numberOfElements={numberOfElements}
            variant="outlined"
            data-testid="product-option-card"
            data-ab-test={
                pinoProductSelected ? 'product-option-card-selected' : undefined
            }
            selected={pinoProductSelected}
            onClick={() => onSelectProductOption(product)}
            component="article"
        >
            {pill && (
                <ProductHighlightPill
                    {...{ squeeze: squeezeBestValue, ...pill }}
                />
            )}

            <StyledCardHeader>
                <StyledBoldText>
                    {product.variant === ProductVariant.Monthly && (
                        <>1 month rolling</>
                    )}
                    {isFixedOrStudentProductVariant && (
                        <>{frequency.term} months</>
                    )}
                    {isDayPassProductVariant && <>{frequency.term} day pass</>}
                </StyledBoldText>
                <StyledPriceContainer $wrap={wrapPriceDescription}>
                    {isKickerInUse && orientation === 'horizontal' && (
                        <StyledStrikethroughPrice>
                            {decimalToPoundsAndPence(
                                kickerPriceMonthlyFee,
                                true,
                            )}
                        </StyledStrikethroughPrice>
                    )}

                    <StyledPrice $color={contentColor}>
                        {finalPrice}
                    </StyledPrice>

                    {showPricePostfix &&
                        getKickerPricePostfixText(
                            kickerPriceDuration as number,
                            product.variant,
                        )}
                </StyledPriceContainer>
            </StyledCardHeader>

            <StyledJoiningFeeText>
                {product.joiningFee ? (
                    <>
                        <StyledBoldText>
                            +{' '}
                            {decimalToPoundsAndPence(product.joiningFee, false)}{' '}
                        </StyledBoldText>
                        joining fee
                    </>
                ) : (
                    <>
                        <StyledBoldText>+ no </StyledBoldText>joining fee
                    </>
                )}
            </StyledJoiningFeeText>

            <StyledHorizontalRule color={dividerColor} />

            <StyledCardFooter>
                <div>
                    {isKickerInUse && orientation === 'horizontal' && (
                        <>
                            <StyledBoldText>
                                {decimalToPoundsAndPence(kickerPriceMonthlyFee)}{' '}
                            </StyledBoldText>
                            after {addOrdinalSuffix(kickerPriceDuration)} month
                        </>
                    )}
                    {product.variant === ProductVariant.Fixed && (
                        <>
                            equal to
                            <StyledBoldText>
                                {' '}
                                {decimalToPoundsAndPence(
                                    value / frequency.term,
                                )}
                            </StyledBoldText>
                            /month
                        </>
                    )}
                </div>
                <div>
                    <StyledPinoOptionButton
                        selected={pinoProductSelected}
                        buttonLocation="card"
                        title={title}
                        data-testid={getDataTestIdString(
                            product,
                            frequency.term,
                        )}
                    >
                        {pinoButtonContent}
                    </StyledPinoOptionButton>
                </div>
            </StyledCardFooter>
        </StyledCard>
    );
};
