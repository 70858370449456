import {
    MembershipPinoProducts,
    MonthlyProductTitlesMap,
} from './products.types';
import {
    ProductVariant,
    GymProduct,
    MembershipPrice,
    Product,
    Products,
    TggMembershipType,
    PinoMonthlyProduct,
    PinoProduct,
    MonthlyProducts,
    PinoProductTitle,
    TggPinoProductTitle,
} from '@tgg/common-types';

export function formatProductsForUI(products: GymProduct[]): Products {
    const initialValue: Products = {
        [ProductVariant.Monthly]: {},
        [ProductVariant.DayPass]: [],
        [ProductVariant.Student]: [],
        [ProductVariant.Fixed]: [],
    };

    const mappedProducts = products.reduce((accumulator, currentItem) => {
        const {
            id,
            description,
            variant,
            term,
            expectedPaymentFrequency,
            price,
            dayPassDays,
            tggMembershipType,
            joiningFee,
            kickerPriceDuration,
            kickerPriceMonthlyFee,
            isKickerPriceInForce,
            classification,
        } = currentItem;
        const mappedPriceObject: MembershipPrice = {
            value: price,
            frequency: {
                term,
                ...(variant === 'Monthly'
                    ? {
                          paymentFrequency: 'Monthly',
                      }
                    : {
                          paymentFrequency: 'OneOff',
                          termType: variant === 'DayPass' ? 'Days' : 'Months',
                      }),
            },
            kickerPriceDuration,
            kickerPriceMonthlyFee,
            isKickerPriceInForce,
        };

        const mappedProduct: Product = {
            id,
            description,
            variant,
            price: mappedPriceObject,
            expectedPaymentFrequency,
            dayPassDays: dayPassDays.length > 0 ? dayPassDays : null,
            membershipType: tggMembershipType,
            classification,
            ...(joiningFee && { joiningFee }),
        };

        if (tggMembershipType === TggMembershipType.Do_It) {
            // @ts-ignore
            accumulator.Monthly[TggMembershipType.Do_It] = mappedProduct;
        }

        if (tggMembershipType === TggMembershipType.Live_It) {
            // @ts-ignore
            accumulator.Monthly[TggMembershipType.Live_It] = mappedProduct;
        }

        if (tggMembershipType === TggMembershipType.UltimateMonthly) {
            // @ts-ignore
            accumulator.Monthly[TggMembershipType.UltimateMonthly] =
                mappedProduct;
        }

        if (tggMembershipType === TggMembershipType.StandardMonthly) {
            // @ts-ignore
            accumulator.Monthly[TggMembershipType.StandardMonthly] =
                mappedProduct;
        }

        if (tggMembershipType === TggMembershipType.OffPeakMonthly) {
            // @ts-ignore
            accumulator.Monthly[TggMembershipType.OffPeakMonthly] =
                mappedProduct;
        }

        if (variant === 'Student') {
            accumulator.Student?.push(mappedProduct);
        }

        if (variant === 'DayPass') {
            accumulator.DayPass?.push(mappedProduct);
        }

        if (variant === 'Fixed') {
            accumulator.Fixed?.push(mappedProduct);
        }

        return accumulator;
    }, initialValue);

    mappedProducts.DayPass = [...mappedProducts.DayPass].sort(
        (a, b) => a.price.value - b.price.value,
    );

    return mappedProducts;
}

export function getFromPriceForProducts(products: Products): number | null {
    if (
        typeof products !== 'object' ||
        !Object.prototype.hasOwnProperty.call(products, 'Monthly')
    ) {
        return null;
    }

    let initialValue: number | null = null;

    const monthlyMembershipTypes = Object.keys(
        products.Monthly as Record<string, Product>,
    );

    monthlyMembershipTypes.forEach(membershipType => {
        const productMonthlyPrice = (
            products.Monthly?.[
                membershipType as keyof Products[ProductVariant.Monthly]
            ] as unknown as Product
        ).price.value;

        if (initialValue === null) {
            initialValue = productMonthlyPrice;
        } else if (productMonthlyPrice < initialValue) {
            initialValue = productMonthlyPrice;
        }
    });

    return initialValue;
}

export const getPinoProducts = (products: Products): MembershipPinoProducts => {
    return {
        [ProductVariant.Monthly]: getPinoMonthlyProducts(products.Monthly),
        [ProductVariant.Fixed]: getPinoFixedProducts(products.Fixed),
        [ProductVariant.Student]: getPinoStudentProducts(products.Student),
        [ProductVariant.DayPass]: getPinoDayPassProducts(products.DayPass),
    };
};

export const hasPinoProduct = (
    monthlyProducts: Partial<MonthlyProducts>,
): boolean => {
    const hasUltimateProduct =
        TggMembershipType.UltimateMonthly in monthlyProducts;
    const hasStandardProduct =
        TggMembershipType.StandardMonthly in monthlyProducts;
    const hasOffPeakProduct =
        TggMembershipType.OffPeakMonthly in monthlyProducts;

    return hasUltimateProduct || hasStandardProduct || hasOffPeakProduct;
};

export const getPinoMonthlyProducts = (
    products: Partial<MonthlyProducts>,
): PinoMonthlyProduct[] => {
    const pinoProducts: PinoMonthlyProduct[] = [];

    if (products[TggMembershipType.UltimateMonthly]) {
        pinoProducts.push(
            products[TggMembershipType.UltimateMonthly] as PinoMonthlyProduct,
        );
    }

    if (products[TggMembershipType.StandardMonthly]) {
        pinoProducts.push(
            products[TggMembershipType.StandardMonthly] as PinoMonthlyProduct,
        );
    }

    if (products[TggMembershipType.OffPeakMonthly]) {
        pinoProducts.push(
            products[TggMembershipType.OffPeakMonthly] as PinoMonthlyProduct,
        );
    }

    return pinoProducts;
};

const fixedPinoProductsList = new Set([
    TggMembershipType.UltimateFixed12,
    TggMembershipType.UltimateFixed9,
    TggMembershipType.UltimateFixed6,
    TggMembershipType.UltimateFixed3,
    TggMembershipType.StandardFixed12,
    TggMembershipType.StandardFixed9,
    TggMembershipType.StandardFixed6,
    TggMembershipType.StandardFixed3,
    TggMembershipType.OffPeakFixed12,
    TggMembershipType.OffPeakFixed9,
    TggMembershipType.OffPeakFixed6,
    TggMembershipType.OffPeakFixed3,
]);

export const getPinoFixedProducts = (products: Product[]): PinoProduct[] => {
    const newProducts = products.some(({ membershipType }) =>
        fixedPinoProductsList.has(membershipType),
    );

    const displayLegacyProduct = !products.some(
        ({ membershipType }) =>
            membershipType === TggMembershipType.UltimateFixed12,
    );

    const pinoFixedProducts = products.filter(product => {
        const isPino = product.classification === 'Pino';
        const isLegacy =
            product.membershipType === TggMembershipType.Live_It_Fixed;

        return (
            (isPino && !isLegacy) ||
            (isPino && displayLegacyProduct && isLegacy)
        );
    }) as PinoProduct[];

    return newProducts ? pinoFixedProducts : (products as PinoProduct[]);
};

export const getPinoStudentProducts = (
    products: Product[],
): PinoMonthlyProduct[] => {
    const studentPinoProducts = products.filter(product => {
        return (
            product.membershipType ===
                TggMembershipType.StudentFitquestGymAccess ||
            product.membershipType === TggMembershipType.Student3 ||
            product.membershipType === TggMembershipType.Student6 ||
            product.membershipType === TggMembershipType.Student9 ||
            product.membershipType === TggMembershipType.Student12
        );
    }) as PinoMonthlyProduct[];

    return studentPinoProducts;
};

export const getPinoDayPassProducts = (
    products: Product[],
): PinoMonthlyProduct[] => {
    const dayPassPinoProducts = products.filter(product => {
        return (
            product.membershipType === TggMembershipType.DayPass ||
            product.membershipType === TggMembershipType.DayPass3 ||
            product.membershipType === TggMembershipType.DayPass5
        );
    }) as PinoMonthlyProduct[];

    return dayPassPinoProducts;
};

export const monthlyProductTitleMap: MonthlyProductTitlesMap = {
    [TggMembershipType.UltimateMonthly]: TggPinoProductTitle.Ultimate,
    [TggMembershipType.StandardMonthly]: TggPinoProductTitle.Standard,
    [TggMembershipType.OffPeakMonthly]: TggPinoProductTitle.OffPeak,
    [TggMembershipType.StudentFitquestGymAccess]: TggPinoProductTitle.Student,
    [TggMembershipType.Student3]: TggPinoProductTitle.Student,
    [TggMembershipType.Student6]: TggPinoProductTitle.Student,
    [TggMembershipType.Student9]: TggPinoProductTitle.Student,
    [TggMembershipType.Student12]: TggPinoProductTitle.Student,
    [TggMembershipType.DayPass]: TggPinoProductTitle.DayPass,
    [TggMembershipType.DayPass3]: TggPinoProductTitle.DayPass,
    [TggMembershipType.DayPass5]: TggPinoProductTitle.DayPass,
};

export const getPinoMembershipTitle = (
    membershipType: TggMembershipType,
): PinoProductTitle | '' => {
    if (membershipType === TggMembershipType.Live_It_Fixed)
        return TggPinoProductTitle.Ultimate;
    const mappedProduct = Object.values(TggPinoProductTitle).find(range =>
        membershipType.toLowerCase().includes(range.replace(/-|\s/, '')),
    );

    return mappedProduct ?? '';
};
