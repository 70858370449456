export const addOrdinalSuffix = (term: number) => {
    const digits = [term % 10, term % 100];
    const ordinals = ['st', 'nd', 'rd', 'th'];
    const oPattern = [1, 2, 3, 4];
    const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];

    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? term + ordinals[digits[0] - 1]
        : term + ordinals[3];
};
