import { Price } from './pricing.types';

/**
 * Utility to determine if there is a membership kicker discount to be displayed and returns relevant values to be rendered.
 * only to be used in the Membership component which is driven from the 'AccountTemplates' object in the join/options endpoint.
 * @param Price
 * @returns message string that can be shown on payment tiles.
 */
export const getMembershipKickerDiscountValues = ({
    price,
    isKickerPriceInForce,
    kickerPriceMonthlyFee,
    kickerPriceDuration,
}: Price): {
    durationString: string;
    fullPrice: number;
} | null => {
    if (
        !isKickerPriceInForce ||
        !kickerPriceMonthlyFee ||
        !kickerPriceDuration ||
        kickerPriceMonthlyFee === price
    ) {
        return null;
    }

    const durationString =
        kickerPriceDuration > 1 ? `${kickerPriceDuration} months` : `month`;

    return {
        fullPrice: kickerPriceMonthlyFee,
        durationString,
    };
};

/**
 * utility to get an item's previous price based on some conditions.
 * only to be used in the Membership compnent which is driven from the 'AccountTemplates' object in the join/options endpoint.
 * @param Price
 * @returns number | null
 */
export const getMembershipWasPrice = ({
    price,
    originalPrice,
    isKickerPriceInForce,
    kickerPriceMonthlyFee,
}: Price): number | null => {
    if (originalPrice && originalPrice !== price) {
        return originalPrice;
    }

    if (!isKickerPriceInForce || kickerPriceMonthlyFee === price) {
        return null;
    }

    return kickerPriceMonthlyFee ?? null;
};

/**
 * utility that can be used to determine what the discount message should be for monthly items.
 * @param discountDuration the number of months the discount lasts for.
 * @returns message string that can be shown on payment tiles.
 */
export const getYourPaymentDiscountMessage = (
    discountDuration: number,
    monthly = true,
): string | null => {
    const actualKickerPriceDuration = monthly
        ? discountDuration - 1
        : discountDuration;

    if (actualKickerPriceDuration < 1) {
        return null;
    }

    if (!monthly && discountDuration === 1) {
        return '1st month';
    }

    const durationString =
        actualKickerPriceDuration > 1
            ? `${actualKickerPriceDuration} months`
            : `1 month`;

    return `Discount applies for ${durationString}, then will return to full price.`;
};

/**
 * utility to get the previous price of an item. can be used for joining fees as well as other payment items.
 * @param nowPrice is the current price of the payment item. originalPrice is the previous price for the payment itme.
 * @returns the 'previous' price of an item so that it can crossed out in the UI. OR null in which case the payment item is not disounted and is full price.
 */
export const getWasPrice = ({
    nowPrice,
    originalPrice,
}: {
    nowPrice: number | null;
    originalPrice: number | null;
}): number | null => {
    return nowPrice !== null && nowPrice !== originalPrice
        ? originalPrice
        : null;
};
