import { SelectedMembershipPinoProducts } from '../PinoProductOptions/PinoProductOptions.types';
import {
    PinoMonthlyProduct,
    PinoProduct,
    ProductVariant,
    TggMembershipType,
} from '@tgg/common-types';
import { GymDto } from '@tgg/services';

export const isGymOpen24Hours = (openingHours: GymDto['openingHours']) => {
    const mappedOpeningHours = Object.values(openingHours);

    return mappedOpeningHours.every(
        openingHoursInDay =>
            openingHoursInDay.OpeningTime === '00:00:00' &&
            openingHoursInDay.ClosingTime === '23:59:59',
    );
};

export const createMembershipTermsForSelectedProduct = (
    selectedProduct: PinoMonthlyProduct,
    fixedProducts: PinoProduct[],
): SelectedMembershipPinoProducts => ({
    [ProductVariant.Monthly]: selectedProduct,
    [ProductVariant.Student]: selectedProduct,
    [ProductVariant.Fixed]: fixedProducts
        .filter(({ membershipType }) =>
            fixedMembershipTermsForSelectedProductMap[
                selectedProduct.membershipType
            ].includes(membershipType),
        )
        .sort(fixedProductsSort),
});

const fixedMembershipTermsForSelectedProductMap = {
    [TggMembershipType.UltimateMonthly]: [
        TggMembershipType.UltimateFixed12,
        TggMembershipType.UltimateFixed9,
        TggMembershipType.UltimateFixed6,
        TggMembershipType.UltimateFixed3,
        // TODO: remove when new fixed-term products can go to PAT and prod
        TggMembershipType.Live_It_Fixed,
    ],
    [TggMembershipType.StandardMonthly]: [
        TggMembershipType.StandardFixed12,
        TggMembershipType.StandardFixed9,
        TggMembershipType.StandardFixed6,
        TggMembershipType.StandardFixed3,
    ],
    [TggMembershipType.OffPeakMonthly]: [
        TggMembershipType.OffPeakFixed12,
        TggMembershipType.OffPeakFixed9,
        TggMembershipType.OffPeakFixed6,
        TggMembershipType.OffPeakFixed3,
    ],
    [TggMembershipType.StudentFitquestGymAccess]: [
        TggMembershipType.StudentFitquestGymAccess,
    ],
    [TggMembershipType.Student3]: [TggMembershipType.Student3],
    [TggMembershipType.Student6]: [TggMembershipType.Student6],
    [TggMembershipType.Student9]: [TggMembershipType.Student9],
    [TggMembershipType.Student12]: [TggMembershipType.Student12],
    [TggMembershipType.DayPass]: [TggMembershipType.DayPass],
    [TggMembershipType.DayPass3]: [TggMembershipType.DayPass3],
    [TggMembershipType.DayPass5]: [TggMembershipType.DayPass5],
};

const fixedProductsSort = (a: PinoProduct, b: PinoProduct) =>
    b.price.frequency.term - a.price.frequency.term;
