import { Product } from '@tgg/common-types';

export enum DataEventNames {
    PRODUCT_SELECT = 'productSelect',
    CHOOSE_TERM = 'chooseTerm',
    TERM_SELECT = 'termSelect',
    JOIN_NOW = 'joinNow',
}

export type GenericUIDataEvent<D, N extends string> = {
    eventName: N;
    eventData: D;
    eventGymName: string;
};

export type UIDataEvent = GenericUIDataEvent<Product, DataEventNames>;

export type DataEventState = {
    [key in DataEventNames]: () => void;
};

export type EventOne = {
    event: DataEventNames.CHOOSE_TERM | string;
    ctaPosition: 'Product Table';
    branchName: string;
    productRange: string;
    productCategory: string;
    fromPrice: number;
};

export type EventTwo = {
    event: DataEventNames.JOIN_NOW | DataEventNames.TERM_SELECT;
    ctaPosition: 'Term Tile';
    branchName: string;
    productRange: string;
    productName: string;
    productType: string;
    productCategory: string;
    termType: 'Months' | 'Days';
    termLength: number;
    joiningFee: number;
    initialPaymentAmount: number;
    kickerPriceDuration: number;
    kickerPriceMonthlyFee: number;
};

export type GenericDataEvent = EventOne | EventTwo;

export type MappedDataEvent = {
    key: DataEventNames;
    data: EventOne | EventTwo;
};

export type membershipTypeToMembershipRangeResponse =
    | 'ultimate'
    | 'standard'
    | 'off peak'
    | 'non pino product'
    | 'student';
