import { PinoMonthlyProduct, PinoProduct } from '@tgg/common-types';

export const generateCTAHref = (
    productsAvailableAddons:
        | {
              [productId: string]: boolean;
          }
        | undefined,
    selectedProduct: PinoProduct,
    ctaHref: string,
): string => {
    if (productsAvailableAddons?.[selectedProduct.id]) {
        return '/join-now/extras/';
    }

    return ctaHref;
};

export const getPinoOrientation = (
    numberOfProducts: number,
    isDesktop: boolean,
) =>
    (numberOfProducts > 1 && !isDesktop) || (numberOfProducts > 2 && isDesktop)
        ? 'vertical'
        : 'horizontal';

export const getSelectedProduct = (
    products: PinoMonthlyProduct[],
): PinoMonthlyProduct => {
    return products.reduce((accumulator, current) => {
        return accumulator.price.value > current.price.value
            ? accumulator
            : current;
    });
};
