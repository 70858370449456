import { PinoProduct, TggMembershipType } from '@tgg/common-types';

export const getDataTestIdString = (
    product: PinoProduct,
    monthCount: number,
): string => {
    if (product.membershipType === TggMembershipType.StudentFitquestGymAccess) {
        return `select-studentsaver${monthCount}`;
    }
    return `select-${product.membershipType.toLowerCase()}`;
};
