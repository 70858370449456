import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import {
    StyledBestDealChip,
    StyledBestDealChipPlaceholder,
} from './BestDealChip.styled';
import { BestDealChipProperties } from './BestDealChip.types';

export const BestDealChip = ({
    placeholder,
    text = 'best deal',
    isMarginBottom = true,
    isHeightAuto,
}: BestDealChipProperties) =>
    placeholder ? (
        <StyledBestDealChipPlaceholder data-testid="best-deal-chip-placeholder" />
    ) : (
        <StyledBestDealChip
            $isMarginBottom={isMarginBottom}
            $isHeightAuto={isHeightAuto}
        >
            <Icon name="wellbeing" color={palette.common.white} size={22} />
            <span>{text}</span>
        </StyledBestDealChip>
    );

export default BestDealChip;
