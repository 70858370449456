import { Tabs as MuiTabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Theme, palette } from '../../theme';

export const StyledMuiTabs = styled(MuiTabs, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $isSquareOnMobile?: boolean;
}>`
    margin: 0 0.8rem;
    background-color: ${palette.grey[100]};
    border-radius: ${({ $isSquareOnMobile }) =>
        $isSquareOnMobile ? '1.6rem' : '36rem'};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        border-radius: 36rem;
    }

    & .MuiTabs-flexContainer {
        height: 100%;
    }

    & .MuiTabs-indicator {
        height: 100%;
        background-color: ${palette.common.blue};
        border-radius: ${({ $isSquareOnMobile }) =>
            $isSquareOnMobile ? '1.6rem' : '36rem'};

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            border-radius: 36rem;
        }
    }

    button {
        flex-basis: auto;
        min-width: 0;
        min-height: 0;
        padding: 0.8rem;
        color: ${palette.common.blue};
        transition: color 0.3s ease;
    }

    & button.Mui-selected {
        color: ${palette.common.white};
    }

    .MuiTab-iconWrapper {
        z-index: 1;
    }
`;

export const StyledTab = styled(Tab, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $isIconGivenForEveryItem: boolean | undefined;
    $numberOfTabs: number;
    $customStyles: boolean | undefined;
}>`
    font-weight: normal;
    font-size: 1.6rem;
    font-family: thegymgroupsans-Headline, sans-serif;
    text-transform: lowercase;
    opacity: 1;

    span {
        width: ${({ $customStyles }) => $customStyles && '2.5rem'};
    }

    &:focus-visible {
        border: 1px solid ${palette.primary.main};
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.5rem;

        span {
            min-width: ${({ $customStyles }) => $customStyles && '8.1rem'};
        }
    }
`;

export const StyledLabel = styled('span')`
    position: relative;
    z-index: 1;
    font-size: 1.4rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledContent = styled('div')<{ $height: string }>`
    height: ${({ $height }) => $height};
    overflow: hidden;
    transition: height 0.3s ease;
`;
