import { Dialog, useMediaQuery } from '@mui/material';

import { palette, theme } from '../../theme';
import {
    getBenefitsList,
    getCommonBenefitsIcons,
    getLastDaypassBenefitsIcon,
    getLastMembershipBenefitsIcon,
    getLastStudentBenefitsIcon,
} from './PinoOverlay.constants';
import {
    StyledDialogTitle,
    StyledDialogContent,
    StyledCloseButton,
    StyledIcon,
    StyledHeading,
    OverlayContentContainer,
    StyledBenefitsList,
    StyledOverlayHeading,
    StyledHorizontalRule,
    StyledSection,
    StyledSectionHeading,
} from './PinoOverlay.styled';
import { OverlayProperties } from './PinoOverlay.types';

export const PinoOverlay = ({
    title,
    open,
    handleClose,
    pinoTable,
    isOpen24Hours,
}: OverlayProperties) => {
    const fullScreen = useMediaQuery('(max-width:767px)');
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize = isDesktop ? 34 : 22;

    const getLastBenefitsIcon = () => {
        switch (pinoTable) {
            case 'student':
                return getLastStudentBenefitsIcon(iconSize);
            case 'daypass':
                return getLastDaypassBenefitsIcon(iconSize, isOpen24Hours);
            default:
                return getLastMembershipBenefitsIcon(iconSize);
        }
    };

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="overlay"
            aria-describedby="overlay-modal"
            PaperProps={{
                style: {
                    borderRadius: isDesktop ? '1.6rem' : 'unset',
                    maxWidth: isDesktop ? '62.8rem' : '100%',
                },
            }}
        >
            <StyledDialogTitle>
                <StyledHeading variant="h2" component="h1" gutterBottom={false}>
                    {title}
                </StyledHeading>
                <StyledCloseButton
                    onClick={handleClose}
                    aria-label="Close"
                    name="Close"
                >
                    <StyledIcon name="close" color={palette.common.white} />
                </StyledCloseButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <OverlayContentContainer>
                    {!(pinoTable === 'student' || pinoTable === 'daypass') && (
                        <StyledOverlayHeading variant="h3">
                            all of our members benefit from:
                        </StyledOverlayHeading>
                    )}
                    <StyledBenefitsList>
                        {getCommonBenefitsIcons(iconSize)}
                        {getLastBenefitsIcon()}
                    </StyledBenefitsList>
                    <StyledHorizontalRule />
                    <StyledSection>
                        {!(
                            pinoTable === 'student' || pinoTable === 'daypass'
                        ) && (
                            <StyledSectionHeading variant="h3">
                                with our ultimate membership, you get even more
                                benefits:
                            </StyledSectionHeading>
                        )}
                        {getBenefitsList(pinoTable)}
                    </StyledSection>
                </OverlayContentContainer>
            </StyledDialogContent>
        </Dialog>
    );
};
