import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { useState, ChangeEvent, useEffect, useRef } from 'react';

import { theme, palette } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledContent,
    StyledLabel,
    StyledMuiTabs,
    StyledTab,
} from './Tabs.styled';
import { TabItem, TabsProperties } from './Tabs.types';
import { dispatchEvent, EventKey, ProductViewEvent } from '@tgg/services';

const daysOfWeekFull = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

/**
 * Use the `Tabs` component when you want to use tabbed panels.
 */
export function Tabs({
    className,
    isDaysOfTheWeek = false,
    isCustomDays = false,
    customDays,
    items,
    daysOfTheWeekContent,
    tabGroupAriaLabel,
    uniqueId,
    gymName,
    defaultSelectedIndex = 0,
    onChange = () => {},
}: TabsProperties) {
    const isIconGivenForEveryItem =
        !isDaysOfTheWeek && items?.every(item => item.iconName);
    const isIconGivenForSomeItems =
        !isDaysOfTheWeek && items?.some(item => item.iconName);
    const numberOfTabs = isDaysOfTheWeek ? 7 : (items && items?.length) || 2;

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();

    const daysOfWeek =
        isDaysOfTheWeek && !isCustomDays
            ? daysOfWeekFull.map((day: string) => {
                  return {
                      label: isDesktop ? day : day.slice(0, 3),
                  } as TabItem;
              })
            : customDays?.map((day: string) => {
                  return {
                      label: day,
                  } as TabItem;
              });
    const tabItems = isDaysOfTheWeek ? daysOfWeek : items;
    const defaultSelectedTab =
        router.query.changeDate && tabItems
            ? tabItems.length - 1
            : defaultSelectedIndex;

    const [selectedIndex, setSelectedIndex] = useState(defaultSelectedTab);
    const [contentHeight, setTableHeight] = useState('auto');

    const containerReference = useRef(null);
    const innerContentReference = useRef(null);

    useEffect(() => {
        setSelectedIndex(defaultSelectedTab);
    }, [defaultSelectedTab]);

    const handleTabSwitch = (index: number) => {
        const containerElement =
            containerReference.current as unknown as HTMLElement;
        const innerElement =
            innerContentReference.current as unknown as HTMLElement;

        if (containerElement) {
            const startHeight = innerElement.scrollHeight;

            setTableHeight(`${startHeight}px`);
            setSelectedIndex(index);

            requestAnimationFrame(() => {
                const newHeight = innerElement.scrollHeight;
                setTableHeight(`${newHeight}px`);
            });

            containerElement.addEventListener(
                'transitionend',
                () => setTableHeight('auto'),
                { once: true },
            );
        } else {
            setSelectedIndex(index);
        }
    };

    const handleChange = (
        event: ChangeEvent<Record<string, any>>,
        newValue: number,
    ) => {
        const membershipTypesPanel = tabItems && gymName;

        if (membershipTypesPanel) {
            const selectedProductType = tabItems[newValue];

            const selectedProductOptions = selectedProductType.products;

            const listOfProducts = selectedProductOptions?.map(product => {
                const { description, expectedPaymentFrequency, price } =
                    product;
                return {
                    name: description,
                    price: price.value,
                    brand: gymName,
                    category: expectedPaymentFrequency,
                    variant: 'Create',
                    quantity: 1,
                };
            });

            if (listOfProducts) {
                dispatchEvent<ProductViewEvent>(EventKey.PRODUCT_DETAIL, {
                    event: EventKey.PRODUCT_DETAIL,
                    ecommerce: {
                        detail: {
                            products: listOfProducts,
                        },
                    },
                });
            }
        }
        onChange(newValue);
        handleTabSwitch(newValue);
    };

    if (!isDaysOfTheWeek && (!items || items.length === 0)) {
        return null;
    }

    if (items && (items.length === 0 || items.length > 5)) {
        return null;
    }

    if (isIconGivenForSomeItems && !isIconGivenForEveryItem) {
        return null;
    }

    if (isDaysOfTheWeek && !daysOfTheWeekContent) {
        return null;
    }

    return (
        <>
            <StyledMuiTabs
                className={className}
                value={selectedIndex}
                onChange={handleChange}
                selectionFollowsFocus
                variant="fullWidth"
                aria-label={tabGroupAriaLabel}
                $isSquareOnMobile={isIconGivenForEveryItem || !!customDays}
            >
                {tabItems &&
                    tabItems?.map((item, index) => {
                        const {
                            label,
                            iconName,
                            onPaymentTabClick,
                            content,
                            ...analyticsItems
                        } = item;

                        return (
                            <StyledTab
                                label={<StyledLabel>{label}</StyledLabel>}
                                id={`tab-${uniqueId}-${index}`}
                                aria-controls={`tabpanel-${uniqueId}-${index}`}
                                disableFocusRipple
                                onClick={onPaymentTabClick as any}
                                disableRipple
                                key={`tab-${uniqueId}-${label}`}
                                {...analyticsItems}
                                $isIconGivenForEveryItem={
                                    isIconGivenForEveryItem
                                }
                                $numberOfTabs={numberOfTabs}
                                $customStyles={isCustomDays}
                                icon={
                                    iconName && (
                                        <Icon
                                            name={iconName}
                                            size={isDesktop ? 26 : 22}
                                            color={palette.primary.main}
                                        />
                                    )
                                }
                            />
                        );
                    })}
            </StyledMuiTabs>
            {
                //  below are the panels for each of the Tabs above.
                isDaysOfTheWeek && daysOfTheWeekContent ? (
                    <>
                        {daysOfTheWeekContent.map((dayContent, dayIndex) => {
                            return (
                                <div
                                    role="tabpanel"
                                    tabIndex={0}
                                    hidden={selectedIndex !== dayIndex}
                                    id={`tabpanel-${uniqueId}-${dayIndex}`}
                                    aria-labelledby={`tab-${uniqueId}-${dayIndex}`}
                                    key={`${daysOfWeekFull[dayIndex]}`}
                                >
                                    {dayContent}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <StyledContent
                        $height={contentHeight}
                        ref={containerReference}
                        data-testid="content-wrapper"
                    >
                        <div ref={innerContentReference}>
                            {items && items[selectedIndex].content}
                        </div>
                    </StyledContent>
                )
            }
        </>
    );
}

export default Tabs;
