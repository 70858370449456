import { Product, TggMembershipType } from '@tgg/common-types';
import { GymPageJoinNowPayload } from '@tgg/services';

export const a11yProperties = (index: number) => ({
    role: 'tabpanel',
    id: `tabpanel-membership-types-${index}`,
    tabIndex: 0,
    'aria-labelledby': `tab-membership-types-${index}`,
});

export const analyticsPayload = (
    product: Product,
    productType: TggMembershipType,
    gymName: string,
) =>
    JSON.stringify({
        ctaPosition: 'Membership',
        membershipType: productType,
        membershipProduct: product.description,
        joiningFee: product.joiningFee,
        oneOffFee: product.price.value,
        kickerPriceMonthlyFee: product.price.kickerPriceMonthlyFee,
        kickerPriceDuration: product.price.kickerPriceDuration,
        joinNowGym: gymName,
    } as Partial<GymPageJoinNowPayload>);
