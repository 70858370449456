import { AxiosError } from 'axios';
import {
    NextApiRequest,
    NextApiResponse,
    NextApiHandler,
    Redirect,
} from 'next';

import { isAxiosError } from '../axios';
import { type TggTraceCorrelationParameters } from '../logger';
import {
    ApiErrorResponse,
    Methods,
    ErrorResponse,
    RLErrorResponse,
} from './next.types';
import { type AppLogger } from '@tgg/common-types';

export type RedirectObject = {
    redirect: Redirect;
};
export class ApiError extends Error {
    override message: string;

    status: number;

    code?: number | string;

    detail?: string;

    isApiError = true;

    constructor(
        message: string,
        status = 400,
        {
            code,
            detail,
        }: {
            code?: number | string;
            detail?: string;
        } = {},
    ) {
        super(message);
        this.status = status;
        this.code = code;
        this.message = message;
        this.detail = detail;
    }
}

export function isApiError(
    error: unknown | Error | ApiError,
): error is ApiError {
    return typeof (error as ApiError).isApiError !== 'undefined';
}

export function isRLErrorResponse(
    error: unknown | AxiosError<RLErrorResponse> | AxiosError<ApiErrorResponse>,
): error is AxiosError<RLErrorResponse> {
    return (
        typeof (error as AxiosError<RLErrorResponse>)?.response?.data
            ?.Success !== 'undefined'
    );
}

export function isApiErrorResponse(
    error: unknown | AxiosError<RLErrorResponse> | AxiosError<ApiErrorResponse>,
): error is AxiosError<ApiErrorResponse> {
    return (
        typeof (error as AxiosError<ApiErrorResponse>)?.response?.data
            ?.error !== 'undefined'
    );
}

export function isRedirect(object: unknown): object is Redirect {
    return typeof (object as Redirect).destination !== 'undefined';
}

export function isRedirectObject(object: unknown): object is RedirectObject {
    return typeof (object as RedirectObject).redirect !== 'undefined';
}

export const getApiErrorResponseMessage = (error: unknown) => {
    return (
        ((isAxiosError<ErrorResponse>(error) &&
            error.response?.data?.error?.message) ||
            (error as Error)?.message) ??
        'unknown error'
    );
};

export function createNextApiHandler({
    logger,
    getLoggerMetadata,
}: {
    logger: AppLogger;
    getLoggerMetadata: (
        request: NextApiRequest,
    ) => TggTraceCorrelationParameters;
}): NextApiHandler<any> & {
    get: (function_: NextApiHandler) => NextApiHandler<any>;
    post: (function_: NextApiHandler) => NextApiHandler<any>;
} {
    const handlers: {
        [key in Methods]?: NextApiHandler | undefined;
    } = {};

    const mainHandler: NextApiHandler & {
        [key in Methods]: (function_: NextApiHandler) => NextApiHandler<any>;
    } = async (request: NextApiRequest, response: NextApiResponse) => {
        const method = (request.method && request.method?.toLowerCase()) as
            | undefined
            | Methods;

        const loggerMetadata = getLoggerMetadata(request);

        try {
            if (!method || !handlers[method]) {
                const message = `Wrong HTTP method: only ${Object.keys(
                    handlers,
                ).join(', ')} is supported.`;
                throw new ApiError(message, 405);
            }

            await handlers[method]?.(request, response);
        } catch (error_) {
            let status = 500;
            let message = (error_ as Error).message || 'Unknown error';
            let code: number | string | undefined;
            let detail: string | undefined;

            if (isApiError(error_)) {
                status = error_.status;
                message = error_.message;
                code = error_.code;
                detail = error_.detail;
            } else if (
                isAxiosError<RLErrorResponse | ApiErrorResponse>(error_)
            ) {
                message = error_.message;
                status = error_.response?.status || 500;
                if (isRLErrorResponse(error_)) {
                    detail = error_.response?.data?.Message;
                    code = error_.response?.data?.Code;
                }
            }

            const logMessage = [status, message, detail, code]
                .filter(Boolean)
                .join(' ');

            logger.error(
                `${request.url as string}: ${logMessage}`,
                loggerMetadata,
            );

            response.status(status).json({ error: { message, detail, code } });
        }
    };

    const setter = (method: Methods) => (function_: NextApiHandler<any>) => {
        handlers[method] = function_;
        return mainHandler;
    };

    mainHandler.get = setter('get');
    mainHandler.post = setter('post');

    return mainHandler;
}
