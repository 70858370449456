import { styled } from '@mui/material/styles';

import { Theme, containerSmallWidth } from '../../../theme';
import { Heading } from '../../Heading';
import { Paragraph } from '../../Paragraph';

export const StyledHeading = styled(Heading)`
    margin-right: 0;
    margin-left: 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledDescription = styled(Paragraph)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        max-width: ${containerSmallWidth};
        margin: 0 auto;
        text-align: center;
    }
`;

export const StyledPinoSectionHeader = styled('section')`
    margin: 0;
    padding: ${({ theme }) => theme.spacing(30)};
`;

export const StyledOptionButton = styled('div')`
    display: flex;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing(30)}
        ${({ theme }) => theme.spacing(30)} ${({ theme }) => theme.spacing(40)}
        ${({ theme }) => theme.spacing(30)};
`;

interface StyledProductOptionWrapperProperties {
    $visible: boolean;
}

export const StyledProductOptionWrapper = styled(
    'div',
)<StyledProductOptionWrapperProperties>`
    width: 100%;
    visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;
