import { getMembershipType } from '../membershipDetails';
import { TggMembershipType } from '@tgg/common-types';

export function randomString(length: number): string {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let index = 0; index < length; index += 1) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        );
    }

    return result;
}

/**
 * returns label that can be displayed in UI
 * @param membershipType the TggMembershipType returned from API - can only be either of DO IT or LIVE IT as these are the only 2 types currently supported
 * @param gymName name of gym
 * @returns formatted string for UI PaymentTile component
 */
export const getFormattedLabelForPaymentItem = (
    tggMembershipType: TggMembershipType | string,
    gymName: string,
): string => {
    const membershipType: string =
        TggMembershipType[tggMembershipType as TggMembershipType] ||
        tggMembershipType;
    const formattedMembershipType = getMembershipType(membershipType);
    return `${formattedMembershipType} @ ${gymName}`;
};

export const capitalise = (text: string): string =>
    text.charAt(0).toUpperCase() + text.slice(1);
