import { Icon } from '../Icon';
import { UnorderedList } from '../UnorderedList';
import {
    StyledBenefitHeading,
    StyledBenefitsItem,
    StyledBoldText,
    StyledIconContainer,
    StyledListItem,
    StyledParagraph,
} from './PinoOverlay.styled';

export const getLastMembershipBenefitsIcon = (iconSize: number) => (
    <StyledBenefitsItem>
        <StyledIconContainer>
            <Icon name="cost" size={iconSize} accessibleTitle="Cost icon" />
        </StyledIconContainer>
        <StyledBenefitHeading gutterBottom={false}>
            low-cost memberships
        </StyledBenefitHeading>
    </StyledBenefitsItem>
);

export const getLastStudentBenefitsIcon = (iconSize: number) => (
    <StyledBenefitsItem>
        <StyledIconContainer>
            <Icon
                name="twentyFourHours"
                size={iconSize}
                accessibleTitle="Unlimited training icon"
            />
        </StyledIconContainer>
        <StyledBenefitHeading gutterBottom={false}>
            unlimited training
        </StyledBenefitHeading>
    </StyledBenefitsItem>
);

export const getLastDaypassBenefitsIcon = (
    iconSize: number,
    isOpen24Hours: boolean,
) => (
    <StyledBenefitsItem>
        <StyledIconContainer>
            <Icon
                name={isOpen24Hours ? 'twentyFourHours' : 'openingHours'}
                size={iconSize}
                accessibleTitle={
                    isOpen24Hours ? 'Twenty four hours' : 'All hours access'
                }
            />
        </StyledIconContainer>
        <StyledBenefitHeading gutterBottom={false}>
            {isOpen24Hours ? '24/7 access' : 'all hours access'}
        </StyledBenefitHeading>
    </StyledBenefitsItem>
);

export const getCommonBenefitsIcons = (iconSize: number) => (
    <>
        <StyledBenefitsItem>
            <StyledIconContainer>
                <Icon
                    name="dumbells"
                    size={iconSize}
                    accessibleTitle="Cost icon"
                />
            </StyledIconContainer>
            <StyledBenefitHeading gutterBottom={false}>
                top kit
            </StyledBenefitHeading>
        </StyledBenefitsItem>
        <StyledBenefitsItem>
            <StyledIconContainer>
                <Icon
                    name="noContract"
                    size={iconSize}
                    accessibleTitle="Contract icon"
                />
            </StyledIconContainer>
            <StyledBenefitHeading gutterBottom={false}>
                no contract
            </StyledBenefitHeading>
        </StyledBenefitsItem>
        <StyledBenefitsItem>
            <StyledIconContainer>
                <Icon
                    name="classes"
                    size={iconSize}
                    accessibleTitle="Classes icon"
                />
            </StyledIconContainer>
            <StyledBenefitHeading gutterBottom={false}>
                unlimited free classes
            </StyledBenefitHeading>
        </StyledBenefitsItem>
    </>
);

const commonFitnessNDiscounts = (
    <>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>
                    Fitness &amp; body composition tracking
                </StyledBoldText>{' '}
                - have the best data to track and reach your goals by using our
                complimentary fitness and body composition tracking (where
                available)
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>
                    Discounts on supplements &amp; clothing
                </StyledBoldText>{' '}
                - in addition to your membership, we also provide discounts and
                savings on your favourite health &amp; wellness brands
            </StyledParagraph>
        </StyledListItem>
    </>
);

const daypassBenefitsList = (
    <UnorderedList>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Unlimited training</StyledBoldText> - access our
                huge range of equipment across all opening hours on your chosen
                date(s)
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Free classes</StyledBoldText> - get coaching,
                support and added motivation with our great range of classes for
                all abilities
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Single-gym access</StyledBoldText> - unlimited
                access to your chosen gym
            </StyledParagraph>
        </StyledListItem>
    </UnorderedList>
);

const studentBenefitsList = (
    <UnorderedList>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Choose 2 gyms</StyledBoldText> - access two
                different locations, allowing you to workout both at university
                and near your home
            </StyledParagraph>
        </StyledListItem>
        {commonFitnessNDiscounts}
    </UnorderedList>
);

const membershipBenefitsList = (
    <UnorderedList>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Access all gyms</StyledBoldText> - Choose from
                over 230 gyms and access 5 at any one time, so you can workout
                whenever you want, wherever you are
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Free membership freeze</StyledBoldText> -
                sometimes life gets in the way. Ultimate gives you the option to
                freeze your monthly membership for up to 1 month at no cost
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Bring a friend for free</StyledBoldText> -
                it&apos;s more fun with a friend and you can bring a friend for
                free 4 times per month, and the same friend 2 times per month
            </StyledParagraph>
        </StyledListItem>
        <StyledListItem>
            <StyledParagraph>
                <StyledBoldText>Free Fiit workouts &amp; plans</StyledBoldText>{' '}
                - take your training to the next level by accessing 1,000+
                workout plans on this no.1 rated fitness app worth over £200
                annually
            </StyledParagraph>
        </StyledListItem>
        {commonFitnessNDiscounts}
    </UnorderedList>
);

export const getBenefitsList = (pinoTable: string) => {
    switch (pinoTable) {
        case 'student':
            return studentBenefitsList;
        case 'daypass':
            return daypassBenefitsList;
        default:
            return membershipBenefitsList;
    }
};
