import { PinoProductWithDetails } from './PinoTable.types';
import { getBenefitsHeadings } from './pino-table.constants';
import {
    PinoMonthlyProduct,
    PinoProduct,
    PinoProductDetails,
    PinoProductTitle,
    Product,
    TggMembershipType,
    TggPinoProductTitle,
    benefitsHeadings,
    dayPassBenefitsHeadings,
    shortenedBenefitsHeadings,
    shortenedStudentBenefitsHeadings,
    studentBenefitsHeadings,
} from '@tgg/common-types';

const additionalBenefitsButtonHeader = 'Additional benefits';
export const benefitToDisplayNumber = 7;

export const shouldDisableProduct = (
    product: Product,
    disabledProduct: TggMembershipType | undefined,
) => {
    if (!disabledProduct) {
        return false;
    }

    return product.membershipType === disabledProduct;
};

export const getKickerRibbonTitle = (kickerPriceDuration: number | null) => {
    const kickerPriceMonthName =
        kickerPriceDuration === 1 ? '1st' : kickerPriceDuration;
    return `${kickerPriceMonthName} month offer`;
};

export const getInitialSelectedProduct = (
    disabledProduct?: TggMembershipType,
    isStudentPinoTable?: boolean,
    isDayPassPinoTable?: boolean,
): PinoProductTitle => {
    if (isStudentPinoTable) {
        return TggPinoProductTitle.Student;
    }

    if (isDayPassPinoTable) {
        return TggPinoProductTitle.DayPass;
    }

    if (!disabledProduct) {
        return TggPinoProductTitle.Ultimate;
    }

    switch (disabledProduct) {
        case TggMembershipType.UltimateMonthly:
            return TggPinoProductTitle.Standard;
        case TggMembershipType.StandardMonthly:
            return TggPinoProductTitle.Ultimate;
        case TggMembershipType.OffPeakMonthly:
            return TggPinoProductTitle.Ultimate;
        case TggMembershipType.StudentFitquestGymAccess:
            return TggPinoProductTitle.Student;
        default:
            return TggPinoProductTitle.Ultimate;
    }
};

export const scrollToRelatedSection = (href: string) => {
    const targetSectionToScrollTo = document.querySelector(href);

    const NAVBAR_HEIGHT = 100;

    if (targetSectionToScrollTo) {
        const offset =
            targetSectionToScrollTo.getBoundingClientRect().top +
            window.pageYOffset -
            NAVBAR_HEIGHT;

        window.scrollTo({ top: offset, behavior: 'smooth' });
    }
};

const sortPINOProductByPosition = (
    currentProduct: PinoProductWithDetails,
    previousProduct: PinoProductWithDetails,
) => currentProduct.position - previousProduct.position;

export const mapPinoProductsWithDetails = (
    products: PinoMonthlyProduct[] | PinoProduct[],
    details: PinoProductDetails,
): PinoProductWithDetails[] => {
    const mappedPinoProducts = products.map(product => ({
        ...product,
        includedBenefits: details[product.membershipType].includedBenefits,
        title: details[product.membershipType].title,
        position: details[product.membershipType].position,
    }));

    return mappedPinoProducts.sort(sortPINOProductByPosition);
};

export const getVisibleBenefits = (
    isStudentPinoTable?: boolean,
    isDayPassPinoTable?: boolean,
    isBenefitsShortened?: boolean,
): string[] => {
    let benefits;

    if (isStudentPinoTable) {
        benefits = isBenefitsShortened
            ? Object.keys(shortenedStudentBenefitsHeadings)
            : studentBenefitsHeadings;
    } else if (isDayPassPinoTable) {
        benefits = dayPassBenefitsHeadings;
    } else {
        benefits = isBenefitsShortened
            ? Object.keys(shortenedBenefitsHeadings)
            : benefitsHeadings;
    }

    return benefits.slice(0, benefitToDisplayNumber);
};

export const getHiddenBenefits = (): string[] => {
    const benefits = getBenefitsHeadings();
    return benefits.length > benefitToDisplayNumber
        ? benefits.slice(benefitToDisplayNumber, benefits.length)
        : [];
};

export const getAdditionalBenefitButton = (): string[] => {
    const benefits = getBenefitsHeadings();
    return benefits.length > benefitToDisplayNumber
        ? [additionalBenefitsButtonHeader]
        : [];
};

export const getHiddenBenefitCount = (): number => {
    const benefits = getBenefitsHeadings();
    return benefits.length > benefitToDisplayNumber
        ? benefits.length - benefitToDisplayNumber
        : 0;
};
