import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { PinoProductOption } from '../PinoProductOption';
import {
    generateCTAHref,
    getPinoOrientation,
} from './PinoProductOptions.helpers';
import {
    StyledAsteriskText,
    StyledButtonContainer,
    StyledCard,
    StyledDevider,
    StyledFixedProductOptions,
    StyledHeader,
    StyledSectionHeading,
} from './PinoProductOptions.styled';
import { ProductOptionsProperties } from './PinoProductOptions.types';
import { ProductOptionIcons } from './ProductOptionIcons';
import { PinoProduct } from '@tgg/common-types';
import { sendAnalyticsDataEvent, DataEventNames } from '@tgg/services';
import { getTestId, monthlyProductTitleMap } from '@tgg/util';

export const PinoProductOptions = ({
    gymName,
    ctaHref,
    products,
    onSelect,
    productsAvailableAddons,
    productHighlightPills,
    ctaName = 'Join Now',
}: ProductOptionsProperties) => {
    const { Monthly: monthlyProduct, Fixed: fixedProducts } = products;
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const router = useRouter();
    const [optionSelected, setOptionSelected] =
        useState<PinoProduct>(monthlyProduct);

    useEffect(() => {
        setOptionSelected(monthlyProduct);
    }, [monthlyProduct]);

    const selectedProductTitle =
        monthlyProductTitleMap[monthlyProduct.membershipType];

    const showFreeFreezeNotice = optionSelected.membershipType
        .toLowerCase()
        .includes('ultimate');

    const { contentColor } = getPinoColor(selectedProductTitle);

    const handleSelectedProductOption = (product: PinoProduct) => {
        sendAnalyticsDataEvent({
            eventName: DataEventNames.TERM_SELECT,
            eventData: product,
            eventGymName: gymName,
        });
        setOptionSelected(product);
    };

    const handleJoinNow = async () => {
        onSelect(optionSelected);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.JOIN_NOW,
            eventData: optionSelected,
            eventGymName: gymName,
        });

        const generatedCTAHref = generateCTAHref(
            productsAvailableAddons,
            optionSelected,
            ctaHref,
        );

        await router.push(generatedCTAHref);
    };

    const orientation = getPinoOrientation(fixedProducts.length, isDesktop);

    return (
        <>
            <StyledSectionHeading variant="h2" gutterBottom={false}>
                2. Select your membership term
            </StyledSectionHeading>
            <StyledCard variant="outlined" data-ab-test={selectedProductTitle}>
                <StyledHeader>
                    <Heading
                        variant="h3"
                        color={contentColor}
                        gutterBottom={false}
                    >
                        {selectedProductTitle}
                    </Heading>
                    <ProductOptionIcons
                        productTitle={selectedProductTitle}
                        color={contentColor}
                    />
                </StyledHeader>
                <PinoProductOption
                    product={monthlyProduct}
                    pinoProductSelected={
                        monthlyProduct.membershipType ===
                        optionSelected.membershipType
                    }
                    numberOfElements={1}
                    title={selectedProductTitle}
                    onSelectProductOption={handleSelectedProductOption}
                    pill={
                        (productHighlightPills &&
                            productHighlightPills?.find(
                                pill =>
                                    pill.membershipType ===
                                    monthlyProduct.membershipType,
                            )) ||
                        undefined
                    }
                />
                {fixedProducts.length > 0 && (
                    <>
                        <StyledDevider>
                            <div>or</div>
                            <HorizontalRule />
                        </StyledDevider>
                        <StyledFixedProductOptions>
                            {fixedProducts.map((product, index) => (
                                <PinoProductOption
                                    key={product.id}
                                    product={product}
                                    pinoProductSelected={
                                        product.membershipType ===
                                        optionSelected.membershipType
                                    }
                                    title={selectedProductTitle}
                                    numberOfElements={fixedProducts.length}
                                    orientation={orientation}
                                    onSelectProductOption={
                                        handleSelectedProductOption
                                    }
                                    pill={
                                        (productHighlightPills &&
                                            productHighlightPills?.find(
                                                pill =>
                                                    pill.membershipType ===
                                                    product.membershipType,
                                            )) ||
                                        undefined
                                    }
                                />
                            ))}
                        </StyledFixedProductOptions>
                        {showFreeFreezeNotice && (
                            <StyledAsteriskText>
                                * free freeze benefit is not included in saver
                                memberships
                            </StyledAsteriskText>
                        )}
                    </>
                )}

                <StyledButtonContainer>
                    <ButtonBase
                        type="button"
                        aria-label={`Join Now with ${optionSelected.description} membership`}
                        text={ctaName}
                        onClick={handleJoinNow}
                        data-testid={getTestId('join-now-button')}
                    />
                </StyledButtonContainer>
            </StyledCard>
        </>
    );
};
