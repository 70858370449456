import { sendGenericDataEvent } from './googleAnalytics.helpers';
import {
    DataEventNames,
    DataEventState,
    GenericDataEvent,
} from './googleAnalytics.types';

export const dataEventState = (
    key: DataEventNames,
    mappedDataEvent: GenericDataEvent,
): void => {
    const state: DataEventState = {
        productSelect: () => sendGenericDataEvent(mappedDataEvent),
        chooseTerm: () => sendGenericDataEvent(mappedDataEvent),
        termSelect: () => sendGenericDataEvent(mappedDataEvent),
        joinNow: () => sendGenericDataEvent(mappedDataEvent),
    };

    state[key]();
};
