import { Benefit } from './Membership.types';
import { TggMembershipType } from '@tgg/common-types';

export const constants = {
    [TggMembershipType.Do_It]: {
        title: 'Our basic gym membership',
        benefits: [
            {
                name: 'Unlimited training',
                description:
                    'access anytime to train with our huge range of equipment',
            },
            {
                name: 'Free classes',
                description:
                    'get coaching, support and added motivation with our great range of classes for all abilities',
            },
            { name: '-', description: '' },
            { name: '-', description: '' },
            { name: '-', description: '' },
            { name: '-', description: '' },
            { name: '-', description: '' },
        ] as Benefit[],
    },
    [TggMembershipType.Live_It]: {
        title: 'Best value gym membership',
        benefits: [
            {
                name: 'Unlimited training',
                description:
                    'access anytime to train with our huge range of equipment',
            },
            {
                name: 'Free classes',
                description:
                    'get coaching, support and added motivation with our great range of classes for all abilities',
            },
            {
                name: 'Multi-gym access',
                description:
                    'choose up to 5 gyms at a time & change them whenever you want',
            },
            {
                name: 'Free unlimited use of online workouts & plans on the Fiit app worth £20 per month',
                description:
                    'take your training to the next level by accessing 1,000+ free workout plans worth £20 per month, to use on demand in the gym or at home',
                subtitle: 'Access to Fiit',
            },
            {
                name: 'Bigger discounts & offers',
                description:
                    'our best savings on some of your favourite supplements, nutrition and clothing brands',
            },
            {
                name: 'Bring a friend 4 times a month',
                description:
                    'the same friend can be brought 2 times a month max',
            },
            {
                name: 'Fitness & body composition tracking (where available)',
                description:
                    'unlimited access to our state-of-the-art machinery, FitQuest, (where available) - normally £2.50 a test',
            },
        ] as Benefit[],
    },
    [TggMembershipType.StudentFitquestGymAccess]: {
        title: 'Everything you need to stay fit while studying',
        benefits: [
            {
                name: 'Unlimited training',
                description:
                    'access anytime to train with our huge range of equipment',
            },
            {
                name: 'Free classes',
                description:
                    'get coaching, support and added motivation with our great range of classes for all abilities',
            },
            {
                name: 'Access to 2 gyms',
                description: 'change your second gym whenever you want for £5',
            },
            {
                name: 'Discounts & offers',
                description:
                    'great savings on some of your favourite supplements, nutrition and clothing brands',
            },
            {
                name: 'Body composition tracking (where available)',
                description:
                    'unlimited access to our state-of-the-art machinery, FitQuest, (where available) - normally £2.50 a test',
            },
        ] as Benefit[],
    },
    // Covers all day pass variants
    [TggMembershipType.DayPass]: {
        title: 'Unlimited access for one or more consecutive days starting on your chosen date',
        benefits: [
            {
                name: 'Unlimited training',
                description:
                    'access our huge range of equipment across all opening hours on your chosen date(s)',
            },
            {
                name: 'Free classes',
                description:
                    'get coaching, support and added motivation with our great range of classes for all abilities',
            },
            {
                name: 'Single-gym access',
                description: 'unlimited access to your chosen gym',
            },
        ] as Benefit[],
    },
    [TggMembershipType.Live_It_Fixed]: {
        title: 'Best value gym membership',
        benefits: [
            {
                name: 'Unlimited training',
                description:
                    'access anytime to train with our huge range of equipment',
            },
            {
                name: 'Free classes',
                description:
                    'get coaching, support and added motivation with our great range of classes for all abilities',
            },
            {
                name: 'Multi-gym access',
                description:
                    'choose up to 5 gyms at a time & change them whenever you want',
            },
            {
                name: 'Free unlimited use of online workouts & plans on the Fiit app worth £20 per month',
                description:
                    'take your training to the next level by accessing 1,000+ free workout plans worth £20 per month, to use on demand in the gym or at home',
                subtitle: 'Access to Fiit',
            },
            {
                name: 'Bigger discounts & offers',
                description:
                    'our best savings on some of your favourite supplements, nutrition and clothing brands',
            },
            {
                name: 'Bring a friend 4 times a month',
                description:
                    'the same friend can be brought 2 times a month max',
            },
            {
                name: 'Fitness & body composition tracking (where available)',
                description:
                    'unlimited access to our state-of-the-art machinery, FitQuest, (where available) - normally £2.50 a test',
            },
        ] as Benefit[],
    },
} as const;
